import TopBar from '../../components/TopBar-statements-internal';

export default function Shareholders() {

    return (
        <div>
            <TopBar />
        </div>
    )
}
