import axios from 'axios'

const CHATBOT_URL = process.env.REACT_APP_CHATBOT_URL
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const REFRESHTOKEN_URL = `${BACKEND_URL}/api/v1/auth/refreshtoken`
const MAX_RETRIES = 1
// 創建一個axios實例
export const axiosInstance = axios.create({
    baseURL: BACKEND_URL,
    headers: { 'Content-Type': 'application/json charset=UTF-8' },
    withCredentials: true
})

const handleError = (error: Error) => {
    alert("🔴 查無資料")
    throw error
}

axiosInstance.interceptors.response.use(
    response => response, // 200，直接回傳
    async error => {
        const originalRequest = error.config
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            // 這不是重試過的請求
            originalRequest._retry = true // 標記這個請求已經嘗試過重新獲取token
            try {
                // 刷新token
                await axios.get(REFRESHTOKEN_URL, { withCredentials: true })
                // token刷新成功，重送原始請求
                return axiosInstance(originalRequest)
            } catch (refreshError) {
                return Promise.reject(refreshError)
            }
        }
        // 非401錯誤或已經重試過的請求，直接將錯誤回傳
        return Promise.reject(error)
    }
)

export const takeCookie = async (retryCount = 0) => {
    const cookies = document.cookie.split('; ')  // 分割 cookie 字串為 key=value 組
    let accessToken = null
    for (let i = 0; i < cookies.length; i++) {
        const [key, value] = cookies[i].split('=') // 分割每個 key 和 value
        if (key.trim() === 'chat_token') {
            accessToken = decodeURIComponent(value)
            return accessToken
        }
    }

    if (!accessToken && retryCount < MAX_RETRIES) {
        try {
            await axiosInstance.get('/api/v1/auth/refreshtoken')
            return takeCookie(retryCount + 1)
        } catch (refreshError) {
            if (retryCount >= MAX_RETRIES) {
                console.error('嘗試刷新令牌達到最大次數後失敗。')
                throw new Error('需要驗證。')
            }
        }
    }
    return accessToken
}

export const takeChatBotTokenPOST = async (access_token: string) => {
    try {
        const url = `${CHATBOT_URL}/api/v1/bizbot/login`
        const data = {
            access_token: access_token
        }
        const res = await axios.post(url, data, {
            validateStatus: function (status) {
                return true  // 這會讓所有的回應都被當作成功，不會自動拋出異常
            }
        })
        if (res.status === 401) {
            console.error('驗證 failed, status 401')
        }
        if (res.status !== 200) {
            throw new Error(`Request failed with status: ${res.status}`)
        }
        return res.data
    } catch (error) {
        console.error('API request 失敗:', error)
        throw error
    }
}

export const chatBotMessagePOST = async (chatAccess_token: string, question: string, uuid: string) => {
    try {
        let chatToken = sessionStorage.getItem('bizbot_token')
        if (!chatToken) {
            chatToken = await takeChatBotTokenPOST(chatAccess_token)
            if (!chatToken) {
                throw new Error('Unable to retrieve token')
            }
            sessionStorage.setItem('bizbot_token', chatToken)
        }

        const url = `${CHATBOT_URL}/api/v1/bizbot/chat`
        const data = { question, uuid }
        const config = {
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${chatToken}`
            },
            validateStatus: function (status) {
                return true  // 這會讓所有的回應都被當作成功，不會自動拋出異常
            }
        }

        // First attempt to send the POST request
        const res = await axios.post(url, data, config)

        // 針對 sessionStorage bizbot_token 過期的情況，重新取得 token 並重試
        if ( res.data.message === "Authorization failed" || res.status === 401 ) {
            // Handle token refresh on 401 Authorization failed
            console.error('Authorization failed, refreshing token.')
            chatToken = await takeChatBotTokenPOST(chatAccess_token)
            sessionStorage.setItem('bizbot_token', chatToken)
            // 因為是更新所以用這種寫法
            config.headers['Authorization'] = `Bearer ${chatToken}`
            // Retry the POST request with the new token
            const retryRes = await axios.post(url, data, config)
            if (retryRes.status !== 200) {
                throw new Error(`Retry request failed with status: ${retryRes.status}`)
            }
            return retryRes.data
        } else if (res.status !== 200) {
            throw new Error(`Request failed with status: ${res.status}`)
        }

        return res.data
    } catch (error) {
        // Handle general errors
        handleError(error)
    }
}