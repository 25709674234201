import axios from 'axios'
// types
import { transformData } from './transformData'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const REFRESHTOKEN_URL = `${BACKEND_URL}/api/v1/auth/refreshtoken`

// 創建一個axios實例
export const axiosInstance = axios.create({
    baseURL: BACKEND_URL,
    headers: { 'Content-Type': 'application/json charset=UTF-8' },
    withCredentials: true
})

axiosInstance.interceptors.response.use(
    response => response, // 200，直接回傳
    async error => {
        const originalRequest = error.config
        if (error.response && error.response.status === 401) {
            // 這不是重試過的請求
            if (!error.config._retry) {
                originalRequest._retry = true // 標記這個請求已經嘗試過重新獲取token
                try {
                    // 刷新token
                    await axios.get(REFRESHTOKEN_URL, { withCredentials: true })
                    // token刷新成功，重送原始請求
                    return axiosInstance(originalRequest)
                } catch (refreshError) {
                    redirectToLogin()
                    return Promise.reject(refreshError)
                }
            }
        }
        // 非401錯誤或已經重試過的請求，直接將錯誤回傳
        return Promise.reject(error)
    }
)
function redirectToLogin() {
    window.location.href = '/login'
}

const handleError = (error: Error) => {
    alert("🔴 查無資料")
    throw error
}

export const infoGET = async (domain: string, queryParams?: object, reportType?: string) => {
    try {
        // 移除 reportType 前後的 /，如果 reportType 存在的话
        const reportTypeSegment = reportType ? `/${reportType}` : ''
        const url = `/api/v1/company/${domain}${reportTypeSegment}`
        const res = await axiosInstance.get(url, { params: queryParams })

        const stockInfo = res.data.map(transformData)

        if (stockInfo.length === 0) {
            throw new Error("🔴 Empty response array")
        }
        if (reportTypeSegment === '') {
            // 存在sessionStorage中
            sessionStorage.setItem('companyInfoData', JSON.stringify(stockInfo[0]))
        }
        return stockInfo
    } catch (error) {
        handleError(error)
    }
}

export const ESGGET = async (stockCode: string) => {

    try {
        const url = `/api/v1/company/${stockCode}/ESG`
        const res = await axiosInstance.get(url)
        if (res.status !== 200) {
            handleError(new Error(`Request failed with status: ${res.status}`))
        } else {
            const stockInfo = res.data.map(transformData)
            sessionStorage.setItem('ESGData', JSON.stringify(stockInfo))
            return stockInfo
        }
    } catch (error) {
        handleError(error)
    }
}

export const graphGET = async (stockCode: string) => {
    try {
        const url = `/api/v1/company/${stockCode}/graph`
        const res = await axiosInstance.get(url)
        if (res.status !== 200) {
            handleError(new Error(`Request failed with status: ${res.status}`))
            return null
        } else {
            const stockInfo = res.data
            sessionStorage.setItem('graphData', JSON.stringify(stockInfo))
            return stockInfo
        }
    } catch (error) {
        handleError(error)
        return null
    }
}

export const mapGET = async (address: string) => {
    try {
        const url = `/api/v1/company/address/${address}`
        const res = await axiosInstance.get(url)
        if (res.status !== 200) {
            handleError(new Error(`Request failed with status: ${res.status}`))
            return null
        } else {
            const mapUrl = res.data
            window.open(mapUrl, '_blank') // 在新分頁中開 URL
            return mapUrl
        }
    } catch (error) {
        handleError(error)
        return null
    }
}

export const yearReportGET = async (stockCode: string, year: string) => {
    try {
        const url = `/api/v1/shareholders/pdf/${stockCode}?year=${year}`
        const res = await axiosInstance.get(url)
        if (res.status !== 200) {
            handleError(new Error(`Request failed with status: ${res.status}`))
            return null
        } else {
            const pdfUrl = res.data.signedUrl
            window.open(pdfUrl, '_blank') // 在新分頁中開 URL
            return pdfUrl
        }
    } catch (error) {
        handleError(error)
        return null
    }
}

export const ArchitectureGET = async (stockCode: string, year: string) => {
    try {
        const url = `/api/v1/shareholders/img/${stockCode}?year=${year}`
        const res = await axiosInstance.get(url)
        if (res.status !== 200) {
            handleError(new Error(`Request failed with status: ${res.status}`))
            return null
        } else {
            const pdfUrl = res.data.signedUrl
            return pdfUrl
        }
    } catch (error) {
        handleError(error)
        return null
    }
}

export const memberDirectorGET = async (stockCode: string) => {
    try {
        const url = `/api/v1/company/${stockCode}/director`
        const res = await axiosInstance.get(url)
        if (res.status !== 200) {
            handleError(new Error(`Request failed with status: ${res.status}`))
        } else {
            const stockInfo = res.data.map(transformData)
            if (stockInfo.length === 0) {
                throw new Error("🔴 Empty response array")
            }
            sessionStorage.setItem('directorData', JSON.stringify(stockInfo))
            return stockInfo
        }
    } catch (error) {
        handleError(error)
    }
}

export const memberDirectorSituationGET = async (stockCode: string) => {
    try {
        const url = `/api/v1/company/${stockCode}/independent`
        const res = await axiosInstance.get(url)
        if (res.status !== 200) {
            handleError(new Error(`Request failed with status: ${res.status}`))
        } else {
            const stockInfo = res.data.map(transformData)
            if (stockInfo.length === 0) {
                throw new Error("🔴 Empty response array")
            }
            sessionStorage.setItem('directorSituation', JSON.stringify(stockInfo))
            return stockInfo
        }
    } catch (error) {
        handleError(error)
    }
}

export const chatBotPOST = async (session_id: string, question: string) => {
    try {
        const url = `https://bizbot-rmkqwytxkq-de.a.run.app/api/v1/bizbot`
        const data = {
            session_id: session_id,
            question: question
        }        
        const config = {
            headers: { 'Content-Type': 'application/json; charset=UTF-8' }
        }
        const res = await axios.post(url, data, config)  // 使用 post 方法並傳送 data
        if (res.status !== 200) {
            handleError(new Error(`Request failed with status: ${res.status}`))
        } else {
            return res.data
        }
    } catch (error) {
        handleError(error)
    }
}
