import { useLocation, useNavigate } from 'react-router'
import { useEffect, useState } from 'react'

// mui
import { Grid, InputLabel } from '@mui/material'
import { TextField, InputAdornment, IconButton } from '@mui/material'
import { LocationOn } from '@mui/icons-material'

// types
import { CompanyInfoRow } from '../../types/CompanyInfo.types'
// css
import { inputPropsStyle, inputSX, inputLabelStyle } from '../../css/formStyle'
// utils
import { formatNumberWithCommas } from '../../utils/regex'

// Axios
import { mapGET } from '../../utils/cpmpanyAxios'


const initialFormData: CompanyInfoRow = {
    stockCode: '',
    // 股票代號
    industryClassification: '',
    // 產業類別
    companyName: '',
    // 公司名稱
    abbreviation: '',
    // 公司簡稱
    switchboard: '',
    // 總機
    address: '',
    // 地址
    chairman: '',
    // 董事長
    generalManager: '',
    // 總經理
    spokesman: '',
    // 發言人
    spokespersonTitle: '',
    // 發言人職稱
    spokespersonsPhone: '',
    // 發言人電話
    primaryBusinessActivities: '',
    // 主要經營業務
    establishmentDate: '',
    // 公司成立日期
    taxIdNumber: '',
    // 營利事業統一編號
    paidUpCapital: '',
    // 實收資本額
    listingDate: '',
    // 上市日期
    OTCListingDate: '',
    // 上櫃日期
    emergingStockMarketListingDate: '',
    // 興櫃日期
    IPODate: '',
    // 公開發行日期
    commonStockParValue: '',
    // 普通股每股面額
    preferredStock: '',
    // 特別股
    commonStockDividendOrLossCompensationFrequency: '',
    // 普通股盈餘分派或虧損撥補頻率
    commonStockAnnualDividendYield: '',
    // 普通股年度 (含第4 季或後半年度)現金股息及紅利決議層級
    stockTransferAgent: '',
    // 股票過戶機構
    telephone: '',
    // 電話
    transferAddress: '',
    // 過戶地址
    certifiedPublicAccountingFirm: '',
    // 簽證會計師事務所
    certifiedPublicAccounting1: '',
    // 簽證會計師1
    certifiedPublicAccounting2: '',
    // 簽證會計師2
    remarks: '',
    // 備註
    englishAbbreviation: '',
    // 英文簡稱
    englishFullName: '',
    // 英文全名
    englishAddressStreet: '',
    // 英文通訊地址(街巷弄號)
    englishAddress_CityProvinceCountry: '',
    // 英文通訊地址(縣市國別)
    faxNumber: '',
    // 傳真機號碼
    emailAddress: '',
    // 電子郵件信箱
    companyWebsite: '',
    // 公司網址
    agentSpokesman: '',
    // 代理發言人
    investorRelationsContact: '',
    // 投資人關係聯絡人 
    investorRelationsContactTitle: '',
    // 投資人關係聯絡人 代理發言人職稱
    investorRelationsContactPhone: '',
    // 投資人關係 代理發言人聯絡電話
    investorRelationsContactEmail: '',
    // 投資人關係 代理發言人電子郵件
    companyWebsiteStakeholderRelationsSectionURL: '',
    // 公司網站內利害關係人專區網址
    companyNameChangeApprovalDate: '',
    // 公司名稱變更核准日期
    domain: '',
    // 域名
    dunsNumber: '',
}

export default function CompanyData() {
    const [companyInfoData, setCompanyInfoData] = useState<CompanyInfoRow>(() => {
        // 從sessionStorage拿，如果没有，則使用 initialFormData
        const storedData = sessionStorage.getItem('companyInfoData')
        return storedData ? JSON.parse(storedData) : initialFormData
    })

    return (
        // Container (base): wrap Page Title, Form
        <Grid sx={{ width: '100%', height: "100%", overflow: "auto" }}>
            <Grid item xs={12} sx={{ padding: "20px 20px", background: "white", marginBottom: "10px" }}>

                <Grid container columnSpacing={4} rowSpacing={1} sx={{ height: "100%", textAlign: "left" }}>

                    <Grid item xs={2}>
                        <InputLabel shrink sx={inputLabelStyle} >股票代號 </InputLabel>
                        <TextField
                            // {...register("id", { required: true, value: formData.id })}=
                            value={companyInfoData.stockCode}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <InputLabel shrink sx={inputLabelStyle} >產業類別 </InputLabel>
                        <TextField
                            value={companyInfoData.industryClassification}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <InputLabel shrink sx={inputLabelStyle} >公司簡稱 </InputLabel>
                        <TextField
                            value={companyInfoData.abbreviation}

                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <InputLabel shrink sx={inputLabelStyle} >公司名稱 </InputLabel>
                        <TextField
                            value={companyInfoData.companyName}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <InputLabel shrink sx={inputLabelStyle} >總機 </InputLabel>
                        <TextField
                            value={companyInfoData.switchboard}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <InputLabel shrink sx={inputLabelStyle} >營利事業統一編號 </InputLabel>
                        <TextField
                            value={companyInfoData.taxIdNumber}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <InputLabel shrink sx={inputLabelStyle} >董事長 </InputLabel>
                        <TextField
                            value={companyInfoData.chairman}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <InputLabel shrink sx={inputLabelStyle} >總經理 </InputLabel>
                        <TextField
                            value={companyInfoData.generalManager}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <InputLabel shrink sx={inputLabelStyle} >發言人 </InputLabel>
                        <TextField
                            value={companyInfoData.spokesman}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <InputLabel shrink sx={inputLabelStyle} >發言人職稱 </InputLabel>
                        <TextField
                            value={companyInfoData.spokespersonTitle}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <InputLabel shrink sx={inputLabelStyle} >發言人電話 </InputLabel>
                        <TextField
                            value={companyInfoData.spokespersonsPhone}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <InputLabel shrink sx={inputLabelStyle} >代理發言人 </InputLabel>
                        <TextField
                            value={companyInfoData.agentSpokesman}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >地址 </InputLabel>
                        <TextField
                            value={companyInfoData.address}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton onClick={() => mapGET(companyInfoData.address)}>
                                            <LocationOn />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >主要經營業務 </InputLabel>
                        <TextField
                            value={companyInfoData.primaryBusinessActivities}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >公司成立日期 </InputLabel>
                        <TextField
                            value={companyInfoData.establishmentDate}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >實收資本額 </InputLabel>
                        <TextField
                            value={formatNumberWithCommas(companyInfoData.paidUpCapital)}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >上市日期 </InputLabel>
                        <TextField
                            value={companyInfoData.listingDate}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >上櫃日期 </InputLabel>
                        <TextField
                            value={companyInfoData.OTCListingDate}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >興櫃日期 </InputLabel>
                        <TextField
                            value={companyInfoData.emergingStockMarketListingDate}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >公開發行日期 </InputLabel>
                        <TextField
                            value={companyInfoData.IPODate}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >普通股每股面額 </InputLabel>
                        <TextField
                            value={companyInfoData.commonStockParValue}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >特別股 </InputLabel>
                        <TextField
                            value={formatNumberWithCommas(companyInfoData.preferredStock)}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >普通股盈餘分派或虧損撥補頻率 </InputLabel>
                        <TextField
                            value={companyInfoData.commonStockDividendOrLossCompensationFrequency}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >普通股年度 (含第4 季或後半年度)現金股息及紅利決議層級 </InputLabel>
                        <TextField
                            value={companyInfoData.commonStockAnnualDividendYield}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >股票過戶機構 </InputLabel>
                        <TextField
                            value={companyInfoData.stockTransferAgent}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >電話 </InputLabel>
                        <TextField
                            value={companyInfoData.telephone}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >過戶地址 </InputLabel>
                        <TextField
                            value={companyInfoData.transferAddress}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >簽證會計師事務所 </InputLabel>
                        <TextField
                            value={companyInfoData.certifiedPublicAccountingFirm}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >簽證會計師1 </InputLabel>
                        <TextField
                            value={companyInfoData.certifiedPublicAccounting1}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >簽證會計師2 </InputLabel>
                        <TextField
                            value={companyInfoData.certifiedPublicAccounting2}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >特別股發行 </InputLabel>
                        <TextField
                            // value={companyInfoData.preferredStock}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >公司債發行 </InputLabel>
                        <TextField
                            // value={companyInfoData.preferredStock}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >英文簡稱 </InputLabel>
                        <TextField
                            value={companyInfoData.englishAbbreviation}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >英文全名 </InputLabel>
                        <TextField
                            value={companyInfoData.englishFullName}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >英文通訊地址(街巷弄號) </InputLabel>
                        <TextField
                            value={companyInfoData.englishAddressStreet}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >英文通訊地址(縣市國別) </InputLabel>
                        <TextField
                            value={companyInfoData.englishAddress_CityProvinceCountry}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >傳真機號碼 </InputLabel>
                        <TextField
                            value={companyInfoData.faxNumber}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >電子郵件信箱 </InputLabel>
                        <TextField
                            value={companyInfoData.emailAddress}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >公司網址 </InputLabel>
                        <TextField
                            value={companyInfoData.companyWebsite}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >投資人關係聯絡人 </InputLabel>
                        <TextField
                            value={companyInfoData.investorRelationsContact}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >投資人關係聯絡人職稱 </InputLabel>
                        <TextField
                            value={companyInfoData.investorRelationsContactTitle}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >投資人關係聯絡電話 </InputLabel>
                        <TextField
                            value={companyInfoData.investorRelationsContactPhone}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >投資人關係電子郵件 </InputLabel>
                        <TextField
                            value={companyInfoData.investorRelationsContactEmail}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >公司網站內利害關係人專區網址 </InputLabel>
                        <TextField
                            value={companyInfoData.companyWebsiteStakeholderRelationsSectionURL}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >公司名稱變更核准日期 </InputLabel>
                        <TextField
                            value={companyInfoData.companyNameChangeApprovalDate}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink sx={inputLabelStyle} >公司域名 </InputLabel>
                        <TextField
                            value={companyInfoData.domain}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel shrink sx={inputLabelStyle} >備註 </InputLabel>
                        <TextField
                            value={companyInfoData.remarks !== "" ? companyInfoData.remarks : "-"}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
