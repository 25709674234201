import React, { useEffect, useState } from 'react'

// mui
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, Button, Paper, TableRow } from '@mui/material'

// Axios
import { ArchitectureGET } from '../../utils/cpmpanyAxios'


export default function Architecture() {
    const stockCode = JSON.parse(sessionStorage.getItem("companyInfoData") || '{}')?.stockCode
    const ARCHITECTURE_YEAR = process.env.REACT_APP_ARCHITECTURE_YEAR
    const [architectureImgUrl, setArchitectureImgUrl] = useState('')

    const fetchArchitectureImg = async () => {
        try {
            const response = await ArchitectureGET(stockCode, ARCHITECTURE_YEAR)
            if (response) {
                setArchitectureImgUrl(response)
            }
        } catch (error) {
            console.error('API請求錯誤：', error)
        }
    }
    useEffect(() => {
        fetchArchitectureImg()
    }, [])


    return (
        <div>
            <Box>
                {/* 直接使用URL作為img標籤的src属性 */}
                {architectureImgUrl ? (
                    <img src={architectureImgUrl} alt="" />
                ) : (
                    <div>圖片加載中...</div>
                )}
            </Box>
        </div>
    )
}
