import React, { useState, useCallback, useRef, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { Fab, Tooltip, Paper, TextField, Button, List, ListItem, ListItemText, Box, Typography } from '@mui/material'
import ChatIcon from '@mui/icons-material/Chat'
import { chatBotMessagePOST, takeCookie } from '../utils/chatBotAxios'
import ReactMarkdown from 'react-markdown'


export default function ChatButton() {
    const [isChatOpen, setChatOpen] = useState(false)
    const [messages, setMessages] = useState([])
    const [inputValue, setInputValue] = useState('')
    const [isComposing, setIsComposing] = useState(false)
    const messagesEndRef = useRef(null) // Ref for the messages container
    const [isSending, setIsSending] = useState(false) // 避免連發 request

    // 滾動到底部
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }

    // 為了組合語言輸入，不要使用 debounce
    const handleCompositionStart = () => {
        setIsComposing(true)
    }
    const handleCompositionEnd = () => {
        setIsComposing(false)
    }
    const handleChange = (event) => {
        setInputValue(event.target.value)
    }

    // 檢查 session storage 中是否已存在 UUID，如果不存在則創建一個新的
    const getOrGenerateUuid = () => {
        let uuid = sessionStorage.getItem('chatUuid')
        if (!uuid) {
            uuid = uuidv4()
            sessionStorage.setItem('chatUuid', uuid)
        }
        return uuid
    }

    // 切換聊天窗口的顯示和生成新的 UUID
    const toggleChat = useCallback(() => {
        setChatOpen(prev => !prev)
        getOrGenerateUuid() // 每次打開聊天時生成新的 UUID
    }, [])


    // 發送問題
    const postQuestion = useCallback(async () => {
        if (inputValue.trim() && !isSending) {
            setIsSending(true)
            const userMessage = { text: inputValue, sender: 'user' }
            setMessages(prevMessages => [...prevMessages, userMessage])
            try {
                const chatAccess_token = await takeCookie()
                const uuid = sessionStorage.getItem('chatUuid') // 從 session storage 獲取 UUID
                const resData = await chatBotMessagePOST(chatAccess_token, inputValue, uuid)
                console.log("🚀 ~ postQuestion ~ chatAccess_token:", chatAccess_token)
                const botMessage = { text: resData, sender: 'bot' }
                setMessages(prevMessages => [...prevMessages, botMessage])
                setInputValue('')
            } catch (error) {
                console.error('❌ error:', error)
            }
            setIsSending(false)
            setTimeout(scrollToBottom, 100) // 確保添加訊息後發生滾動
        }
    }, [inputValue, isSending])

    // 按下 Enter 鍵發送訊息
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !isComposing) {
            // 確保不在組合輸入過程中
            postQuestion()
            setInputValue('')
            setTimeout(scrollToBottom, 100) // 確保添加訊息後發生滾動
        }
    }
    // 監聽消息狀態，若消息被清空則生成新的 UUID
    useEffect(() => {
        if (messages.length === 0) {
            sessionStorage.setItem('chatUuid', uuidv4())
        }
    }, [messages]) // 依賴消息狀態

    return (
        <Box>
            <Tooltip title="開啟聊天" onClick={toggleChat}>
                <Fab
                    color="primary"
                    style={{ position: 'fixed', right: 16, bottom: 16 }}
                    onClick={toggleChat}
                >
                    <ChatIcon />
                </Fab>
            </Tooltip>
            <Paper elevation={3}
                sx={{
                    position: 'fixed',
                    right: 20,
                    bottom: 80,
                    width: ['100%', '450px'],
                    height: 550,
                    display: isChatOpen ? 'block' : 'none',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}>
                <List sx={{ maxHeight: 'calc(100% - 60px)', overflow: 'auto', mb: 7.5 }}>
                    {messages.map((message, index) => (
                        <ListItem key={index} sx={{ justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start' }}>
                            <ListItemText
                                primary={
                                    <>
                                        <strong>{message.sender === 'user' ? 'You: ' : 'Bizbot: '}</strong>
                                        <br />
                                        <ReactMarkdown>{message.text}</ReactMarkdown>
                                    </>
                                }
                            />
                        </ListItem>
                    ))}
                    <div ref={messagesEndRef} />  {/* Invisible element to scroll into view */}
                </List>
                <Box sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    borderTop: 1,
                    borderColor: 'divider',
                    padding: 1,
                    bgcolor: 'background.paper',
                    display: 'flex',
                }}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="輸入消息..."
                        value={inputValue}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        onCompositionStart={handleCompositionStart}
                        onCompositionEnd={handleCompositionEnd}
                        disabled={isSending}
                        size="small"
                        sx={{
                            mr: 1,
                            "& .MuiOutlinedInput-root": {
                                borderRadius: '16px' // 這會使輸入框更圓
                            }
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={postQuestion}
                        sx={{
                            borderRadius: '13px' // 這會使按鈕更圓
                        }}
                    >
                        發送
                    </Button>
                </Box>
            </Paper>
        </Box>
    )
}
