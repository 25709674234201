export const tipsComparison = {
    // 現金流量表
  '繼續營業單位稅前淨利（淨損）': '除去非經常性項目之外實際上取得的淨利潤或淨損失。',
  '本期稅前淨利（淨損）': '經營利潤或虧損，尚未扣除所得稅的數字。',
  '折舊費用': '公司長期資產，隨著時間的推移而損耗的數值。',
  '攤銷費用': '特定費用或支出分攤到其相關的時間期間，以反映在該期間內的實際使用或消耗情況。',
  '利息費用': '公司為借入資金而支付的成本。',
  '利息收入': '公司由出借資金或投資中獲得的利息金額。',
  '股利收入': '投資者從持有股票中獲得的收益，表示公司分發給股東的一部分利潤。',
  '收益費損項目合計': '公司財報中包括各種收益和費用的總和。',
  '調整項目合計': '公司財報中包括的各種非常規、非經常或特殊性質的調整項目的總和，這些項目不屬於正常營運活動但在特定情況下被納入財務報表。',
  '營運產生之現金流入（流出）': '正常營業活動中實際上產生的現金流量，通過計算營業活動中的現金收入和支出之差來得到。',
  '營業活動之淨現金流入（流出）': '實際營業所產生的現金流，排除非現金項目和其他非營業活動的現金流。',
  '投資活動之淨現金流入（流出）': '實際投資所產生的現金流，通過計算投資活動中的現金收入和支出之差得出。',
  '籌資活動之淨現金流入（流出）': '實際籌資所產生的現金流，通過計算籌資活動中的現金收入和支出之差得出。',
  '期初現金及約當現金餘額': '期初公司在其帳戶中擁有的現金及可立即轉換為現金的資產總額。',
  '期末現金及約當現金餘額': '期末公司在其帳戶中擁有的現金及可立即轉換為現金的資產總額。',
  '資產負債表帳列之現金及約當現金': '公司擁有的現金和迅速轉換為現金的資產總額，以「現金及約當現金」這個帳戶呈現在資產負債表中。',
  // 資產負債表
  '現金及約當現金':'公司擁有的現金和即時可轉換成現金的資產。',
  '應收帳款淨額':'公司各類應收帳款總額扣除按規定提取尚未轉銷的壞帳準備的金額。',
  '存貨':'公司持有的產品、以及用於生產過程中的原材料、在製品和成品。',
  '流動資產合計':'公司可在一年內或營運週期內轉換為現金或現金等價物的總和。',
  '非流動資產合計':'公司所有不易迅速轉換為現金的資產總和。',
  '應付帳款':'公司尚未支付給，供應商或其他合作夥伴的款項。',
  '流動負債合計':'公司短期應支付的債務和責任的總和。',
  '非流動負債合計':'公司長期應支付的債務和責任的總和。',
  '負債總額':'公司對外的義務和債務的總和。',
  '股本合計':'公司股本項目的總和，代表公司股東投資的總額。',
  '保留盈餘合計':'公司保留盈餘項目的總和，代表公司歷史上累計的未分派盈餘。',
  '其他權益合計':'公司其他權益項目的總和，包括不屬於股本和保留盈餘。',
  '權益總額':'公司股東權益項目的總和，反映了公司的淨值，包括股本、保留盈餘。',
  '負債及權益總計':'公司負債和權益項目的總和，反映了公司的全部資金來源。',
  // 綜合損益表
  '營業收入合計':'公司賣出商品或提供勞務的收入總和。',
  '營業成本合計':'公司生產或購入產品時所產生的成本總和。',
  '營業毛利（毛損）':'公司在銷售商品或提供服務的過程中，扣除直接成本後實際上取得的毛利潤或損失。',
  '營業費用合計':'公司營運過程中所衍生出來的費用。',
  '營業利益（損失）':'公司實際上取得的淨收入，扣除了營業成本、銷售收入和行政開支等。',
  '營業外收入及支出合計':'營業外收入及營業外支出相減的結果; 營業外是指公司營業項目以外的活動。',
  '稅前淨利（淨損）':'公司的經營利潤或虧損，尚未扣除所得稅的數字。',
  '所得稅費用（利益）合計':'公司應納的所得稅費用或享有的所得稅利益。',
  '本期淨利（淨損）':'公司營運活動的總損益，即所有收入扣除所有費用所得出的數字。',
  '本期綜合損益總額':'公司在一定報告期內的綜合財務結果，包括淨利（淨損）以及其他可能影響股東權益的項目。',
  '基本每股盈餘':'公司淨利潤（淨收入）除以加權平均普通股份的數量。',
  '稀釋每股盈餘':'評估公司盈利能力的指標，淨收入除以加權平均普通股+包括可轉換債券、優先股等。',
  // 權益變動表
  '增減普通股股本':'公司普通股數量的變動，可能包括新發行股票、回購股票或股份轉讓等活動。',
  '期末普通股股本':'期末公司上市的、已發行的普通股的總量。',
  '增減股本合計':'公司股本發生的變動總額。',
  '期末股本合計':'期末公司的總股本數量。',
  '增減資本公積':'公司資本公積帳戶發生的變動總額，資本公積包括公司募集資金以外的各種資本形式，通常不屬於普通股股本。',
  '期末資本公積':'期末公司資本公積帳戶的餘額。資本公積包括公司募集資金以外的各種資本形式，通常不屬於普通股股本。',
  '增減法定盈餘公積':'公司法定盈餘公積帳戶發生的變動總額。法定盈餘公積是公司根據法律或監管機構的要求而建立的一個資本帳戶，用於保留特定的盈餘。',
  '期末法定盈餘公積':'期末公司法定盈餘公積帳戶的餘額。法定盈餘公積是公司根據法律或監管機構的要求而建立的一個資本帳戶，用於保留特定的盈餘。',
  '增減特別盈餘公積':'特別盈餘公積帳戶發生的變動總額。特別盈餘公積是公司用來處理特殊情況或法定要求下的盈餘的一個資本帳戶。',
  '期末特別盈餘公積':'期末公司特別盈餘公積帳戶的餘額。特別盈餘公積是公司用來處理特殊情況或法定要求下的盈餘的一個資本帳戶。',
  '增減未分配盈餘（或待彌補虧損）':'公司未分配盈餘（或待彌補虧損）帳戶發生的變動總額。此帳戶是公司歷史上累積的盈餘，即公司獲得的盈利扣除已經分派給股東的部分。',
  '期末未分配盈餘（或待彌補虧損）':'期末公司未分配盈餘（或待彌補虧損）帳戶的餘額。此帳戶是公司歷史上累積的盈餘，即公司獲得的盈利扣除已經分派給股東的部分。',
  '增減保留盈餘合計':'公司保留盈餘帳戶發生的總變動。保留盈餘是公司歷史上累計的未分派盈餘，即公司在過去賺取的盈利扣除已分派給股東的部分。',
  '期末保留盈餘合計':'期末公司保留盈餘帳戶的最終餘額。保留盈餘是公司歷史上累計的未分派盈餘，即公司在過去賺取的盈利扣除已經分派給股東的部分。',
  '增減國外營運機構財務報表換算之兌換差額':'匯率變動，導致國外營運機構的財務報表在換算成本地貨幣時所出現的總額變動。',
  '期末國外營運機構財務報表換算之兌換差額':'期末匯率變動，導致國外營運機構的財務報表在換算成本地貨幣時所出現的總額變動。',
  '增減其他權益項目合計':'公司其他權益項目發生的總變動。其他權益是指公司股東權益中除了股本、資本公積和保留盈餘以外的各種權益項目。',
  '期末其他權益項目合計':'期末公司其他權益項目的總餘額。其他權益項目包括股東權益中除了股本、資本公積和保留盈餘之外的各種權益項目。',
  '增減歸屬於母公司業主之權益總計':'公司歸屬於母公司業主的權益項目發生的總變動。歸屬於母公司業主的權益包括股本、資本公積、保留盈餘以及其他歸屬於母公司業主的權益項目。',
  '期末歸屬於母公司業主之權益總計':'期末公司歸屬於母公司業主的權益項目的總餘額。歸屬於母公司業主的權益包括股本、資本公積、保留盈餘以及其他歸屬於母公司業主的權益項目。',
  '增減權益總額':'公司股東權益總額發生的總變動。股東權益總額包括股本、資本公積、保留盈餘、其他權益項目等。',
  '期末權益總額':'期末公司所有權益項目的總和。這包括股本、資本公積、保留盈餘以及其他歸屬於股東的權益項目。'
}
