import React from "react"
import { Navigate, RouteObject } from "react-router-dom"
import App from "../App"
import Company from "../page/Company"
import Home from '../page/Home'
import Hubspot from '../page/Hubspot'
import Related from '../page/Related'
import CashFlow from "../page/CompanyRepot/CompanyCashFlow"
import Shareholders from "../page/CompanyRepot/CompanyShareholders"
import Income from "../page/CompanyRepot/CompanyIncome"
import Financial from "../page/CompanyRepot/CompanyFinancia"
import Login from "../page/Login"

const routes: RouteObject[] = [
    {
        index: true,
        path: "login",
        element: <Login />,
    },
    {
        path: "",  // Set an empty string for the path to make it the default route
        element: <Home />,
    },
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "company",
                element: <Company />,
                children: [
                    { path: "cashflow", element: <CashFlow /> },
                    { path: "financial", element: <Financial /> },
                    { path: "income", element: <Income /> },
                    { path: "shareholders", element: <Shareholders /> },
                ]
            },
            {
                path: "related",
                element: <Related />,
            },
            {
                path: "hubspot/:domain",
                element: <Hubspot />,
            },
        ]
    },
]
export default routes
