import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState, useMemo } from 'react'
// export csv
import { CSVLink } from 'react-csv'

// mui
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Tooltip, Box } from '@mui/material'
import { Grid, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography, Button, Select, MenuItem } from '@mui/material'
import { Info } from '@mui/icons-material'

// css
import { inputPropsStyle, statementInputSX, stickyColumn } from '../css/formStyle'

// form select choices
import { years, season, unit } from '../shared/selectOptions'

// axios
import { infoGET } from '../utils/cpmpanyAxios'

// utils
import { formatNumberWithCommas } from '../utils/regex'
import { tipsComparison } from '../utils/tipsComparison'

function CustomTable({ data, unitValue }) {
    // 被排除的列名
    const excludedColumns = ['stockCode', 'year', 'season']

    // 幫助函數，用於獲取年份和季度的顯示字符串
    const getYearAndSeason = (row) => {
        return unitValue === 'y' ? `${row.year}年` : `${row.year}年 Q${row.season}`
    }

    // 使用useMemo來提高性能，避免不必要的重新計算
    const columns = useMemo(() => Object.keys(data[0]).filter(column => !excludedColumns.includes(column)), [data])
    const transformedData = useMemo(() => data.map(row => ({ ...row, yearSeasonDisplay: getYearAndSeason(row) })), [data, unitValue])

    // 渲染表頭行
    const renderHeaderRow = () => (
        <TableRow>
            <TableCell sx={stickyColumn}>會計項目</TableCell>
            {transformedData.map((row, index) => (
                <TableCell key={index} sx={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
                    {row.yearSeasonDisplay}
                </TableCell>
            ))}
        </TableRow>
    )

    // 渲染表格主體
    const renderBodyRows = () => columns.map((column, columnIndex) => (
        <TableRow key={columnIndex}>
            <TableCell sx={stickyColumn}>
                {column}
                {' '}
                {tipsComparison[column] && (
                    <Tooltip title={tipsComparison[column]}>
                        <Info sx={{ fontSize: 15, color: "#fcd34c" }} />
                    </Tooltip>
                )}
            </TableCell>
            {transformedData.map((row, rowIndex) => (
                <TableCell key={`${columnIndex}_${rowIndex}`} sx={{ textAlign: 'right' }}>
                    {formatNumberWithCommas(row[column])}
                </TableCell>
            ))}
        </TableRow>
    ))

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableBody>
                    {renderHeaderRow()}
                    {renderBodyRows()}
                </TableBody>
            </Table>
        </TableContainer>
    )
}


export default function TopBar() {
    const [data, setData] = useState([]) // save backend data
    const location = useLocation() // to get url path
    const [activeItem, setActiveItem] = useState('')
    const [loading, setLoading] = useState(false)
    // queryParams
    const [startYear, setStartYear] = useState('')
    const [endYear, setEndYear] = useState('')
    const [startSeason, setStartSeason] = useState('')
    const [endSeason, setEndSeason] = useState('')
    const [unitValue, setUnitValue] = useState('y')
    // const [formData, setFormData] = useState<Account>(initialFormData) // render current form state
    const navigate = useNavigate()

    const ItemOnClick = (itemName: string) => {
        setActiveItem(itemName)
        // Note: does not cause page reload
        navigate(`../${itemName}`, { state: { startYear, endYear, startSeason, endSeason, unitValue } })
    }

    const searchForReport = async () => {
        try {
            setLoading(true)
            // 從session拿資料 解析 JSON 字串
            const stockCode = JSON.parse(sessionStorage.getItem("companyInfoData") || '{}')?.stockCode
            // 用當前網址獲取 reportType
            const reportType = window.location.href.split('/').pop()
            const queryParams = {
                startYear: startYear,
                endYear: endYear,
                startSeason: startSeason,
                endSeason: endSeason,
                unit: unitValue,
                reportType: reportType
            }
            const response = await infoGET(stockCode, queryParams, reportType)
            setData(response)
        } catch (error) {
            console.error("API請求錯誤：", error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        const currentPath = location.pathname.split('/')[2]
        setActiveItem(currentPath)
        // 紀錄選取的資料
        const { state } = location
        if (state && state.startYear) {
            setStartYear(state.startYear)
        }
        if (state && state.endYear) {
            setEndYear(state.endYear)
        }
        if (state && state.startSeason) {
            setStartSeason(state.startSeason)
        }
        if (state && state.endSeason) {
            setEndSeason(state.endSeason)
        }
        if (state && state.unitValue) {
            setUnitValue(state.unitValue)
        }
    }, [location])

    return (
        <Grid sx={{ width: '100%', height: "100%" }}>
            <Grid container rowSpacing={1} sx={{ padding: "20px", background: "white" }}>

                <Grid item xs={1.5} >
                    <ListItem disablePadding >
                        <ListItemText>
                            <Typography variant="body1" fontWeight="bold" pl="12px">
                                財務報表索引
                            </Typography>
                        </ListItemText>
                    </ListItem>

                    <List dense sx={{ textAlign: "left", scroll: "auto" }}>
                        <ListItem key={"income"} disablePadding>
                            <ListItemButton selected={activeItem === "income"} onClick={() => ItemOnClick("income")}>
                                <ListItemText primary={"綜合收益表"} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem key={"cashflow"} disablePadding>
                            <ListItemButton selected={activeItem === "cashflow"} onClick={() => ItemOnClick("cashflow")}>
                                <ListItemText primary={"現金流量表"} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem key={"shareholders"} disablePadding >
                            <ListItemButton selected={activeItem === "shareholders"} onClick={() => ItemOnClick("shareholders")}>
                                <ListItemText primary={"權益變動表"} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem key={"financial"} disablePadding >
                            <ListItemButton selected={activeItem === "financial"} onClick={() => ItemOnClick("financial")}>
                                <ListItemText primary={"資產負債表"} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Grid>

                <Grid item xs={10} >
                    <Toolbar variant="dense" sx={{ pb: '25px' }}>
                        <Typography
                            align='center'
                            sx={{
                                fontWeight: "bold", fontSize: "11.5px", width: "8%", pl: "10px", pr: "5px", whiteSpace: 'nowrap'
                            }}
                            component="div"
                        >
                            資料單位
                        </Typography>
                        <Select
                            name="searchBar-id"
                            value={unitValue}
                            onChange={(event) => setUnitValue(event.target.value)}
                            size="small"
                            autoComplete="off"
                            inputProps={inputPropsStyle}
                            sx={statementInputSX}
                        // disabled={searchKey_name.length > 0}    // lock this bar if the other bar is active
                        >
                            {unit.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>

                        <Typography
                            align='center'
                            sx={{
                                fontWeight: "bold", fontSize: "11.5px", width: "8%", pl: "10px", pr: "5px", whiteSpace: 'nowrap'
                            }}
                            component="div"
                        >
                            開始年份
                        </Typography>
                        <Select
                            name="searchBar-id"
                            value={startYear}
                            onChange={(event) => setStartYear(event.target.value)}
                            size="small"
                            autoComplete="off"
                            inputProps={inputPropsStyle}
                            sx={statementInputSX}
                        // disabled={searchKey_name.length > 0}    // lock this bar if the other bar is active
                        >
                            {years.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                        {unitValue !== 'y' && (
                            <Typography
                                align='center'
                                sx={{
                                    fontWeight: "bold", fontSize: "11.5px", width: "8%", pl: "10px", pr: "5px", whiteSpace: 'nowrap'
                                }}
                                component="div"
                            >
                                開始季度
                            </Typography>
                        )}
                        {unitValue !== 'y' && (
                            <Select
                                name="searchBar-id"
                                value={startSeason}
                                onChange={(event) => setStartSeason(event.target.value)}
                                size="small"
                                autoComplete="off"
                                inputProps={inputPropsStyle}
                                sx={statementInputSX}
                            >
                                {season.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}

                        <Typography
                            align='center'
                            sx={{
                                fontWeight: "bold", fontSize: "11.5px", width: "8%", pl: "10px", pr: "5px", whiteSpace: 'nowrap'
                            }}
                            component="div"
                        >
                            結束年份
                        </Typography>
                        <Select
                            name="searchBar-id"
                            value={endYear}
                            onChange={(event) => setEndYear(event.target.value)}
                            size="small"
                            autoComplete="off"
                            inputProps={inputPropsStyle}
                            sx={statementInputSX}
                        // disabled={searchKey_name.length > 0}    // lock this bar if the other bar is active
                        >
                            {years.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                        {unitValue !== 'y' && (
                            <Typography
                                align='center'
                                sx={{
                                    fontWeight: "bold", fontSize: "11.5px", width: "8%", pl: "10px", pr: "5px", whiteSpace: 'nowrap'
                                }}
                                component="div"
                            >
                                結束季度
                            </Typography>
                        )}
                        {unitValue !== 'y' && (
                            <Select
                                name="searchBar-id"
                                value={endSeason}
                                onChange={(event) => setEndSeason(event.target.value)}
                                size="small"
                                autoComplete="off"
                                inputProps={inputPropsStyle}
                                sx={statementInputSX}
                            // disabled={searchKey_name.length > 0}    // lock this bar if the other bar is active
                            >
                                {season.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}

                        <Button variant="contained" size="small" disableElevation color='primary' sx={{ borderRadius: "10px", fontSize: "10px", padding: "5px 10px", margin: "0px 10px" }}
                            onClick={searchForReport}
                            disabled={loading}
                        >
                            搜尋
                        </Button>
                    </Toolbar>

                    <Grid container >
                        {data.length > 0 && <CustomTable data={data} unitValue={unitValue} />}
                    </Grid>

                    {/* Render the download button conditionally */}
                    {data.length > 0 && (
                        <Box display="flex" justifyContent="space-between" alignItems="center" p={1}>
                            <Typography sx={{ textAlign: 'left', fontSize: '12px' }}>
                                單位：仟元
                            </Typography >
                            <Button sx={{ fontSize: '12px' }}>
                                <CSVLink data={data} filename={"財務報表.csv"}>
                                    下載CSV
                                </CSVLink>
                            </Button>
                            <Typography sx={{ textAlign: 'right', fontSize: '12px' }}>
                                <a href="https://mops.twse.com.tw/mops/web/t164sb04" target="_blank" rel="noopener noreferrer">
                                    詳情請至公開資訊觀測站
                                </a>
                            </Typography>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}
