import React from 'react'
import { useEffect, useState } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid } from '@mui/material'
// Axios
import { memberDirectorGET, memberDirectorSituationGET } from '../../utils/cpmpanyAxios'

export default function CompanyDirector() {
    const [directorData, setDirectorData] = useState([])
    const [directorSituationData, setDirectorSituationData] = useState([])
    const stockCode = JSON.parse(sessionStorage.getItem("companyInfoData") || '{}')?.stockCode
    const director = async () => {
        try {
            const response = await memberDirectorGET(stockCode)
            if (response && response.length > 0) {
                setDirectorData(response)
            }
        } catch (error) {
            console.error('API請求錯誤：', error)
        }
    }

    const directorSituation = async () => {
        try {
            const response = await memberDirectorSituationGET(stockCode)
            if (response && response.length > 0) {
                setDirectorSituationData(response)
            }
        } catch (error) {
            console.error('API請求錯誤：', error)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const parsedData = JSON.parse(sessionStorage.getItem("companyInfoData") || '{}')?.stockCode
            const storedDirector = JSON.parse(sessionStorage.getItem("directorData") || '{}')?.[0]?.stockCode
            const storedDirectorSituation = JSON.parse(sessionStorage.getItem("directorSituation") || '{}')?.[0]?.stockCode

            if (parsedData === storedDirector && parsedData === storedDirectorSituation) {
                const directorData = JSON.parse(sessionStorage.getItem("directorData") || '[]')
                const directorSituationData = JSON.parse(sessionStorage.getItem("directorSituation") || '[]')
                setDirectorData(directorData)
                setDirectorSituationData(directorSituationData)
            } else {
                await director()
                await directorSituation()
            }
        }
        fetchData()
    }, [])

    return (
        <Paper sx={{ width: '100%', overflowX: 'auto', overflowY: 'hidden' }}>
            <TableContainer>
                <div style={{ paddingTop: '10px', fontWeight: 'bold', fontSize: '1.5em' }}>董事及監察人出(列)席董事會情形</div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>職稱</TableCell>
                            <TableCell>姓名(或代表人姓名)</TableCell>
                            <TableCell>所代表法人姓名</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {directorData.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{row.職稱}</TableCell>
                                <TableCell>{row.姓名}</TableCell>
                                <TableCell>{row.所代表法人姓名 ? row.所代表法人姓名 : '--'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                <div style={{ paddingTop: '10px', fontWeight: 'bold', fontSize: '1.5em' }}>獨立董事現職、經歷及兼任情形</div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '9%' }}>職稱</TableCell>
                            <TableCell style={{ width: '8%' }}>姓名</TableCell>
                            <TableCell> 就任日期</TableCell>
                            <TableCell style={{ width: '30%' }}>主要現職</TableCell>
                            <TableCell style={{ width: '20%' }}>主要經歷</TableCell>
                            <TableCell style={{ width: '20%' }}>兼任公司名稱</TableCell>
                            <TableCell style={{ width: '20%' }}>兼任公司職稱</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {directorSituationData.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{row.職稱 ? row.職稱 : '--'}</TableCell>
                                <TableCell>{row.姓名 ? row.姓名 : '--'}</TableCell>
                                <TableCell>{row.就任日期 ? row.就任日期 : '--'}</TableCell>
                                <TableCell dangerouslySetInnerHTML={{ __html: row.主要現職 ? row.主要現職.replace(/,/g, '<br>') : '--' }} />
                                <TableCell dangerouslySetInnerHTML={{ __html: row.主要經歷 ? row.主要經歷.replace(/,/g, '<br>') : '--' }} />
                                <TableCell dangerouslySetInnerHTML={{ __html: row.兼任公司名稱 ? row.兼任公司名稱.replace(/,/g, '<br>') : '--' }} />
                                <TableCell dangerouslySetInnerHTML={{ __html: row.兼任職稱 ? row.兼任職稱.replace(/,/g, '<br>') : '--' }} />

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}
