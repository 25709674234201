import React, { useEffect, useRef, useState } from 'react'
import { DataSet, Network } from 'vis-network/standalone/esm/vis-network'
// Axios
import { graphGET } from '../../utils/cpmpanyAxios'

export default function Graph() {
    const networkRef = useRef(null)
    const [formattedData, setFormattedData] = useState([])
    const stockCode = JSON.parse(sessionStorage.getItem("companyInfoData") || '{}')?.stockCode

    // 生成 name 到 id 的 Map
    const createNameToIdMap = (nodes) => {
        const map = new Map()
        nodes.forEach(node => {
            map.set(node.label, node.id)
        })
        return map
    }

    // 生成邊
    const generateEdges = (nodes) => {
        const nameToIdMap = createNameToIdMap(nodes)
        const edges = []

        nodes.forEach(node => {
            if (node.group) {
                const groupNames = node.group.split(',').map(name => name.trim())
                groupNames.forEach(groupName => {
                    const toId = nameToIdMap.get(groupName)
                    if (toId !== undefined && toId !== node.id) { // 避免nodes自己連自己
                        edges.push({ from: node.id, to: toId })
                    }
                })
            }
        })
        return edges
    }

    // 生成 nodes
    const processData = (data) => {
        let idCounter = 1
        const entities = new Map()
        const labelSet = new Set() // 檢查 label 是否重複的 Set
        const companyDirectorsMap = new Map() // 存儲公司和對應的董事名單
        if (data.length > 0) {
            const primaryLabel = data[0].abbreviation
            labelSet.add(primaryLabel)
            entities.set(primaryLabel, {
                id: 0,
                label: primaryLabel,
                title: data[0].companyName + ' ' + data[0].stockCode,
                group: "start",
                color: "#ffcc00",
                size: 43
            })
        }
        data.forEach(item => {
            const directorLabel = item.directorName
            const directorTitle = item.directorName + ' ' + item.stockCode
            const otherCompanyLabel = item.otherCompanyName || ''
            const otherCompanyUniqueLabel = item.otherCompanyName + '_' + (item.otherStockcode || '')
            const otherCompanyTitle = item.otherCompanyName + ' ' + (item.otherStockcode || '')
            const otherDirectorLabel = item.otherDirectorName || ''
            const otherDirectorTitle = item.otherDirectorName ? item.otherDirectorName + ' ' + (item.otherStockcode ? + item.otherStockcode : '') : ''
            // 更新公司和董事
            const updateCompanyDirectorsMap = (company, director) => {
                if (director) {
                    const directors = companyDirectorsMap.get(company) || new Set()
                    directors.add(director)
                    // 理論要加 但是不加在我們的專案中反而是對的：）
                    // companyDirectorsMap.set(company, directors)
                }
            }

            updateCompanyDirectorsMap(item.abbreviation, directorLabel)
            updateCompanyDirectorsMap(otherCompanyLabel, directorLabel)
            updateCompanyDirectorsMap(item.otherCompanyNameLevel2, otherDirectorLabel)

            // 檢查加董事
            if (directorLabel && !labelSet.has(directorLabel)) {
                labelSet.add(directorLabel)
                entities.set(directorLabel, {
                    id: idCounter++,
                    label: directorLabel,
                    title: directorTitle,
                    group: item.abbreviation,
                    color: "#a6d188",
                    size: 25
                })
            }

            // 檢查加其他公司
            if (otherCompanyLabel && !labelSet.has(otherCompanyUniqueLabel)) {
                labelSet.add(otherCompanyLabel)
                entities.set(otherCompanyLabel, {
                    id: idCounter++,
                    label: otherCompanyLabel,
                    title: otherCompanyTitle,
                    group: directorLabel,
                    color: "#97CBFF",
                    size: 25
                })
            }

            // 檢查加其他董事
            if (otherDirectorLabel && !labelSet.has(otherDirectorLabel)) {
                labelSet.add(otherDirectorLabel)
                entities.set(otherDirectorLabel, {
                    id: idCounter++,
                    label: otherDirectorLabel,
                    title: otherDirectorTitle,
                    group: otherCompanyLabel,
                    color: "#a6d188",
                    size: 25
                })
            }

            // 如果有其他公司且未重複 則加入
            if (item.otherCompanyNameLevel2 && !labelSet.has(item.otherCompanyNameLevel2)) {
                labelSet.add(item.otherCompanyNameLevel2)
                entities.set(item.otherCompanyNameLevel2, {
                    id: idCounter++,
                    label: item.otherCompanyNameLevel2,
                    title: item.otherCompanyNameLevel2, // 没有股票代号
                    group: otherDirectorLabel,
                    color: "#97CBFF",
                    size: 25
                })
            }
        })

        // 更新公司 nodes 的 group 字段
        entities.forEach((entity, label) => {
            // 跳過id = 0 的公司
            if (entity.id !== 0) {
                if (companyDirectorsMap.has(label)) {
                    entity.group = Array.from(companyDirectorsMap.get(label)).join(',')
                }
            }
        })
        return Array.from(entities.values())
    }

    const graph = async () => {
        try {
            const response = await graphGET(stockCode)
            if (response && response.length > 0) {
                const newFormattedData = processData(response)
                setFormattedData(newFormattedData) // 更新 formattedData 狀態
            }
        } catch (error) {
            console.error('API請求錯誤：', error)
        }
    }
    // 拿資料
    useEffect(() => {
        const fetchData = async () => {
            await graph()
        }
        fetchData()
    }, [stockCode])

    // 畫圖
    useEffect(() => {
        let nodes = [
        ]
        if (formattedData.length > 0) {
            nodes = formattedData.map(item => ({
                id: item.id,
                label: item.label,
                title: item.title,
                group: item.group,
                color: item.color,
                size: item.size
            }))
        }
        // 邊
        const edges = generateEdges(formattedData)

        const nodesDataSet = new DataSet(nodes)
        const edgesDataSet = new DataSet(edges)

        const finilltData = {
            nodes: nodesDataSet,
            edges: edgesDataSet
        }


        // 圖表選項
        const options = {
            nodes: {
                shape: "dot",
                font: {
                    size: 20, // px
                }
            },
            physics: {
                forceAtlas2Based: {
                    gravitationalConstant: -60,
                    centralGravity: 0.005,
                    springLength: 210,
                    springConstant: 0.68,
                },
                maxVelocity: 146,
                solver: "forceAtlas2Based",
                timestep: 0.5,
                stabilization: { iterations: 150 },

            },
        }
        // 初始化圖表之前先檢查是否已存在實例
        if (networkRef.current && !networkRef.current.hasOwnProperty('body')) {
            new Network(networkRef.current, finilltData, options);
        }
    }, [formattedData])

    return (
        <div ref={networkRef} style={{ height: '900px', width: '100%' }} />
    )
}
