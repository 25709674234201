import React from 'react';
import { useLocation } from "react-router-dom";
import './App.css';
import { Navigate, Outlet } from "react-router-dom";
import { Box, Stack } from '@mui/material';
import SideBar from './components/SideBar-internal';
import ChatButton from './components/ChatButton';



function App() {
  const location = useLocation();

  const shouldShowSidebar = location.pathname !== '/'; // 不是首頁時顯示側邊欄

  return (
    <div className="App" style={{ background: "rgba(65,105,225,0.1)", overflow: "auto" }}>
      {/* <Stack direction="row"> */}
      <Box sx={{ display: 'flex' }}>
        <SideBar />
        <Outlet />
      </Box>
      <ChatButton /> {/* 添加chat按鈕 */}
      {/* </Stack> */}
    </div>
  );
}

export default App;
