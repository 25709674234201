
const comparisonTable = {
    // 現金流量表 cashFlowStatement
    // 'stockCode': '股票代號',
    // 'year': '年份',
    // 'season': '季度',
    'remark': '備註',

    'netProfitBeforeTaxOfContinuingBusinessUnits': '繼續營業單位稅前淨利（淨損）',
    'netProfitBeforeTaxForThePeriod': '本期稅前淨利（淨損）',
    'depreciationExpense': '折舊費用',
    'amortizationExpense': '攤銷費用',
    'interestExpense': '利息費用',
    'interestIncome': '利息收入',
    'dividendIncome': '股利收入',
    'incomeAndExpenseItems': '收益費損項目合計',
    'adjustmentItem': '調整項目合計',
    'cashInflowsFromOperations': '營運產生之現金流入（流出）',
    'netCashFlowFromOperatingActivities': '營業活動之淨現金流入（流出）',
    'netCashFlowFromInvestingActivities': '投資活動之淨現金流入（流出）',
    'netCashFlowFromFinancingActivities': '籌資活動之淨現金流入（流出）',
    'beginningCashAndCashEquivalentBalances': '期初現金及約當現金餘額',
    'cashAndCashEquivalentBalancesAtTheEndOfPeriod': '期末現金及約當現金餘額',
    'cashAndCashEquivalentsOnTheBalanceSheet': '資產負債表帳列之現金及約當現金',

    // 股東權益變動表 shareholdersEquity
    'equityChangeShareCapitalOrdinary': '增減普通股股本',
    'endingBalanceShareCapitalOrdinary': '期末普通股股本',
    'equityChangeTotalShareCapital': '增減股本合計',
    'endingBalanceTotalShareCapital': '期末股本合計',
    'equityChangeCapitalReserve': '增減資本公積',
    'endingBalanceCapitalReserve': '期末資本公積',
    'equityChangeStatutorySurplusReserve': '增減法定盈餘公積',
    'endingBalanceStatutorySurplusReserve': '期末法定盈餘公積',
    'equityChangeSpecialSurplusReserve': '增減特別盈餘公積',
    'endingBalanceSpecialSurplusReserve': '期末特別盈餘公積',
    'equityChangeUndistributedEarnings': '增減未分配盈餘（或待彌補虧損）',
    'endingBalanceUndistributedEarnings': '期末未分配盈餘（或待彌補虧損）',
    'equityChangeTotalRetainedEarnings': '增減保留盈餘合計',
    'endingBalanceTotalRetainedEarnings': '期末保留盈餘合計',
    'equityChangeExchangeDifferencesInTranslationFinancialStatementsOfForeignOperatingInstitutions': '增減國外營運機構財務報表換算之兌換差額',
    'endingBalanceExchangeDifferencesInTranslationFinancialStatementsOfForeignOperatingInstitutions': '期末國外營運機構財務報表換算之兌換差額',
    'equityChangeTotalOtherEquityItems': '增減其他權益項目合計',
    'endingBalanceTotalOtherEquityItems': '期末其他權益項目合計',
    'equityChangeTotalEquityAttributableToOwnersOfParentCompany': '增減歸屬於母公司業主之權益總計',
    'endingBalanceTotalEquityAttributableToOwnersOfParentCompany': '期末歸屬於母公司業主之權益總計',
    'equityChangeTotalEquity': '增減權益總額',
    'endingBalanceTotalEquity': '期末權益總額',

    // 綜合收益表 incomeStatement
    'operatingIncome': '營業收入合計',
    'operatingCosts': '營業成本合計',
    'operatingGrossProfit': '營業毛利（毛損）',
    'operatingExpenses': '營業費用合計',
    'operatingProfit': '營業利益（損失）',
    'nonOperatingIncomeAndExpenses': '營業外收入及支出合計',
    'netProfitBeforeTax': '稅前淨利（淨損）',
    'incomeTaxExpenses': '所得稅費用（利益）合計',
    'netProfitForTheCurrentPeriod': '本期淨利（淨損）',
    'comprehensiveProfitAndLossForThePeriod': '本期綜合損益總額',
    'basicEarningsPerShare': '基本每股盈餘',
    'dilutedEarningsPerShare': '稀釋每股盈餘',

    // 財務狀況表 financialPosition
    'cashAndCashEquivalents': '現金及約當現金',
    'netAccountsReceivable': '應收帳款淨額',
    'stock': '存貨',
    'totalCurrentAssets': '流動資產合計',
    'totalNonCurrentAssets': '非流動資產合計',
    'accountsPayable': '應付帳款',
    'totalCurrentLiabilities': '流動負債合計',
    'totalNonCurrentLiabilities': '非流動負債合計',
    'totalLiabilities': '負債總額',
    'totalShareCapital': '股本合計',
    'totalRetainedEarning': '保留盈餘合計',
    'totalOtherInterests': '其他權益合計',
    'totalEquity': '權益總額',
    'totalLiabilitiesAndEquity': '負債及權益總計',

    // director
    'position': '職稱',
    'name': '姓名',
    'representedLegalPerson': '所代表法人姓名',

    // Independent_Director_Situation 獨立董事現職、經歷及兼任情形
    'takingOfficeDate': '就任日期',
    'mainCurrentPosition': '主要現職',
    'mainExperience': '主要經歷',
    'otherCompanyName': '兼任公司名稱',
    'otherCompanyPosition': '兼任職稱',

    // esg
    // 'companyName': '公司名稱',
    // 'englishAbbreviation': '英文簡稱',
    'declareReason': '申報原因',
    // 'industryClassification': '產業類別',
    'periodCovered': '報告書內容涵蓋期間',
    'guideline': '編製依循準則',
    'verificationUnit': '第三方驗證單位',
    'unitName': '單位名稱',
    'standards': '採用標準',
    'ESGReoprt_Chinese': '公司網站報告書相關資訊(中文版)',
    'ESGReoprtFile_Chinese': '中文版永續報告書檔案',
    'ESGReoprtUploadDate_Chinese': '中文版上傳日期',
    'ESGReoprt_English': '英文版永續報告書網址',
    'ESGReoprtFile_English': '英文版永續報告書檔案',
    'ESGReoprtUploadDate_English': '英文版上傳日期',
    'correctedVersion_Chinese': '中文版永續報告書(修正後版本)',
    'correctedVersion_English': '上傳日期(中文修正後版本)',
    'correctedVersionUploadDate_Chinese': '英文版永續報告書(修正後版本)',
    'correctedVersionUploadDate_English': '上傳日期(英文修正後版本)',
    'reportContactInformation': '報告書聯絡資訊',

}

export const transformData = (info: ArrayBuffer) => {
    const transformed = {};

    for (const key in info) {
        //如果有對應的轉換，使用轉換後的名稱，否則保留原始的名稱
        transformed[comparisonTable[key] || key] = info[key];
    }
    return transformed;
}