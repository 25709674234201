import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

// mui
import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';

// mui icons
import { CorporateFare, AssignmentOutlined } from '@mui/icons-material';


function SideBar() {
    const location = useLocation(); // to get url path
    const [activeItem, setActiveItem] = useState("");
    const [open, setOpen] = useState(false);    // settings list
    const navigate = useNavigate();

    const ItemOnClick = (itemName: string) => {
        setActiveItem(itemName);
        navigate(`/${itemName}`);  // Note: does not cause page reload
    }

    useEffect(() => {
        setActiveItem(location.pathname.split('/')[1]);
    });

    return (
        <Drawer variant="permanent" sx={{
            width: "175px",
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: "175px",
                boxSizing: 'border-box',
            },
        }}>

            <Stack direction="column" sx={{ display: "flex", padding: "20px 20px" }}>
                <img src="/epicloudOld.png" alt="epicloud icon" style={{ width: "60%" }}></img>
                <Typography sx={{ width: "100%", fontSize: "24px", fontWeight: "bold", textAlign: "left" }}>Epic.Bizinfo</Typography>
            </Stack>

            <Divider />

            <List sx={{ height: "60%" }} dense>
                <ListItem key={"company"} disablePadding>
                    <ListItemButton selected={activeItem == "company"} onClick={() => ItemOnClick("company")}>
                        <ListItemIcon sx={{ minWidth: "0px" }}>
                            <CorporateFare />
                        </ListItemIcon>
                        <ListItemText primary={"企業資料"} sx={{ padding: "0px 0px 0px 10px" }} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={"related"} disablePadding>
                    <ListItemButton selected={activeItem == "related"} onClick={() => ItemOnClick("related")}>
                        <ListItemIcon sx={{ minWidth: "0px" }}>
                            <AssignmentOutlined />
                        </ListItemIcon>
                        <ListItemText primary={"企業報告"} sx={{ padding: "0px 0px 0px 10px" }} />
                    </ListItemButton>
                </ListItem>

                {/* <ListItem key={"settings"} disablePadding>
                    <ListItemButton onClick={() => setOpen(!open)}>
                        <ListItemIcon sx={{ minWidth: "0px" }}>
                            <SettingsOutlined />
                        </ListItemIcon>
                        <ListItemText primary={"設定"} sx={{ padding: "0px 0px 0px 10px" }} />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </ListItem>

                <Collapse in={open} timeout="auto" unmountOnExit>
                    <ListItem key={"settings"} disablePadding>
                        <ListItemButton selected={activeItem == "settings-internal-user"} onClick={() => ItemOnClick("settings-internal-user")} >
                            <ListItemIcon sx={{ minWidth: "0px", margin: "0px 0px 0px 20px" }}>
                                <People />
                            </ListItemIcon>
                            <ListItemText primary={"後台使用者維護"} sx={{ padding: "0px 0px 0px 10px" }} />
                        </ListItemButton>
                    </ListItem>

                    <ListItem key={"settings"} disablePadding>
                        <ListItemButton selected={activeItem == "settings-role"} onClick={() => ItemOnClick("settings-role")}>
                            <ListItemIcon sx={{ minWidth: "0px", margin: "0px 0px 0px 20px" }}>
                                <Key />
                            </ListItemIcon>
                            <ListItemText primary={"角色與權限管理"} sx={{ padding: "0px 0px 0px 10px" }} />
                        </ListItemButton>
                    </ListItem>
                </Collapse> */}
            </List>

            <Box sx={{ padding: "10px" }}>
                {/* <Typography
                    sx={{ flex: '1 1 auto', textAlign: "center", fontWeight: "bold" }}
                    id="username"
                    component="div"
                >
                    username
                </Typography>
                <Typography
                    sx={{ flex: '1 1 auto', textAlign: "center", fontSize: "12px" }}
                    id="role"
                    component="div"
                >
                    --
                </Typography>

                <Box sx={{ alignSelf: "center", padding: "20px 10px" }}>
                    <Button size="small" fullWidth={true} variant="contained" onClick={logout}>登出</Button>
                </Box>

                <Typography
                    sx={{ flex: '1 1 auto', textAlign: "center", color: "#808080" }}
                    id="version"
                    component="div"
                >
                    v0.1.0
                </Typography> */}
            </Box>

        </Drawer>
    );
}

// material ui note:
// <Stack>: control horizontal or vertical layout
// <Box>: base block for individual sections

export default SideBar;
