import React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// axios
import { infoGET } from '../utils/cpmpanyAxios'


export default function Hubspot() {
    const { domain } = useParams() // 從路由參數中獲取 domain
    const navigate = useNavigate()

    useEffect(() => {
        async function fetchData() {
            try {
                await infoGET(domain)
                navigate('/company')
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }
        fetchData()
    }, [navigate, domain])

    return (
        <div></div>
    )
}