import React, { useState } from 'react'
export default function DirectorShareholding() {
        const URLindustry = JSON.parse(sessionStorage.getItem("companyInfoData") || '{}')?.industryClassification
        // 這裡應該是 Looker 報表中定義的正確篩選器名稱
        let filterName = "ds10.industry" // 假設 Looker 中的篩選器名稱是 "industry"
        // 這裡是您希望報表按照此參數進行篩選的值
        let filterValue = URLindustry // 這是您希望篩選的行業名稱
        // 創建一個包含篩選器名稱和值的物件
        let params = {
            [filterName]: filterValue
            //"df13": "include0IN光電業"
        }
    
        let paramsAsString = JSON.stringify(params)
        let encodedParams = encodeURIComponent(paramsAsString)
        encodedParams = encodeURIComponent(encodedParams)
        // 換掉encoded的，要變成實際看到的
        let extractedPart = encodedParams.replace('%257B%2522ds10.industry%2522%253A%2522', '')
        const baseUrl = process.env.REACT_APP_LOOKER_URL
        const stockCode = JSON.parse(sessionStorage.getItem("companyInfoData") || '{}')?.stockCode
        const paramsAsUrl = `params=%7B%22df7%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${stockCode}%22%7D`
        // <iframe width="600" height="450" src=
        // "https://lookerstudio.google.com/embed/reporting/1211e33a-4584-4bf5-8083-bf3b50e19a24/page/p_39biel8ujd"
        // frameborder="0" style="border:0" allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
        // const fullUrl = `${baseUrl}${paramsAsUrl}${extractedPart}`
        const fullUrl = `https://lookerstudio.google.com/embed/reporting/1211e33a-4584-4bf5-8083-bf3b50e19a24?${paramsAsUrl}`
        return (
            <div>
                <iframe
                title="bizinfo analyze"
                src={fullUrl}
                frameBorder="0"
                width='100%'
                height='800px'
                >
                </iframe>

            </div>
        )
}
