import TopBar from '../../components/TopBar-statements-internal';


export default function Income() {
    return (
        <div>
            <TopBar />
        </div>
    )
}
