import { useEffect, useState } from "react"
import { Navigate } from "react-router"
import { useSearchParams, useNavigate } from "react-router-dom"

// mui
import { Alert, Box, Button, Snackbar, Typography } from "@mui/material"

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

type Loggedin = "init" | "login" | "logout"

export default function Login() {
    // login error snakebar
    const [open, setOpen] = useState(false)
    const [loggedin, setLoggedin] = useState<Loggedin>("init")
    const [searchParams, setSearchParams] = useSearchParams()

    /**
    * check JWT to determine login or logout state
    */

    const auth = async () => {
        // initial state
        // valid -> login
        // expire or not exist -> logout
        if (document.cookie.indexOf("refresh_token=") == -1) {
            setLoggedin("logout")
            return
        }

        try {
            const res = await fetch(`${BACKEND_URL}/api/v1/auth/refreshtoken`, {
                credentials: "include",
            })
            if (res.status === 200) {
                setLoggedin("login")
            } else {
                setLoggedin("logout")
            }
        } catch (error) {
            console.error("Error during authentication:", error)
            setLoggedin("logout")
        }
    }

    useEffect(() => {
        auth()
    }, [])

    if (loggedin == "init")
        return (<></>)     // blank page

    else if (loggedin == "login")
        return (<Navigate to="/" replace={true} />)

    return (
        <Box sx={{ background: "#F4F7FE", height: "100vh", width: "100vw", textAlign: "center" }}>
            <Box sx={{ height: "95%", paddingTop: "15vh" }}>
                <img src="./epicloud.png" alt="epicloud icon" style={{ width: "200px" }}></img>

                <Typography sx={{ width: "100%", fontSize: "30px", fontWeight: "bold", color: "#2B3674", marginBottom: "30px" }}>
                    上市櫃Bizinfo平台
                </Typography>

                <Button size="small" variant="contained" disableElevation sx={{ width: "200px", borderRadius: "20px", textTransform: "none" }}
                    onClick={() => window.location.href = `${BACKEND_URL}/oauth2/authorization/google`}>
                    {"透過 Google 登入"}
                </Button>
            </Box>

            <Typography sx={{ width: "100%", fontSize: "12px" }}>
                Copyright © 2023 Epic Cloud. All Rights Reserved.
            </Typography>

            {/* error snakebar */}
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                onClose={() => setOpen(false)}
            >
                <Alert severity="error" variant="filled">登入失敗</Alert>
            </Snackbar>
        </Box>
    )
}
