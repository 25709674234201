import React, { useEffect, useState } from 'react'

// mui
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'

// Axios
import { ESGGET } from '../../utils/cpmpanyAxios'

export default function CompanyESG() {
    const [ESGData, setESGData] = useState([])
    const excludedColumns = ['stockCode', 'year', '中文版永續報告書檔案', '英文版永續報告書檔案', '中文版永續報告書(修正後版本)', '英文版永續報告書(修正後版本)', 'companyName', 'englishAbbreviation', 'industryClassification']

    const stockCode = JSON.parse(sessionStorage.getItem("companyInfoData") || '{}')?.stockCode
    const ESG = async () => {
        try {
            const response = await ESGGET(stockCode)
            if (response && response.length > 0) {
                setESGData(response)
            }
        } catch (error) {
            console.error('API請求錯誤：', error)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const parsedData = JSON.parse(sessionStorage.getItem("companyInfoData") || '{}')?.stockCode
            const storedDirector = JSON.parse(sessionStorage.getItem("ESGData") || '{}')?.[0]?.stockCode
            if (parsedData && parsedData === storedDirector) {
                const ESGData = JSON.parse(sessionStorage.getItem("ESGData")) || []
                setESGData(ESGData)
            } else {
                await ESG()
            }
        }
        fetchData()
    }, [])

    return (
        <Paper sx={{ width: '100%', overflowX: 'auto', overflowY: 'hidden' }}>
            <TableContainer>
                <Table>
                    <TableHead>
                        {ESGData && ESGData.length > 0 && Object.keys(ESGData[0])
                            .filter(key => !excludedColumns.includes(key) && key !== 'message') // Exclude 'message' column
                            .map((key, index) => (
                                <TableCell key={index} sx={{ maxWidth: '230px', whiteSpace: 'nowrap' }}>
                                    {key}
                                </TableCell>))}
                    </TableHead>
                    <TableBody>
                        {ESGData && ESGData.length > 0 && ESGData.map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                {Object.entries(row)
                                    .filter(([key]) => !excludedColumns.includes(key))
                                    .map(([key, value], columnIndex) => (
                                        <TableCell key={columnIndex}>
                                            {key === '公司網站報告書相關資訊(中文版)' || key === '英文版永續報告書網址' ? (
                                                <a href={row[key]} target="_blank" rel="noopener noreferrer">
                                                    點擊查看
                                                </a>
                                            ) : (
                                                value as React.ReactNode
                                            )}
                                        </TableCell>
                                    ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>

    )
}