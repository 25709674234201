import React, { useEffect, useState } from 'react'

// mui
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, Button, Paper, TableRow, Select, MenuItem, Typography } from '@mui/material'

// css
import { statementInputSX, inputPropsStyle } from '../../css/formStyle'

// form select choices
import { ADyears } from '../../shared/selectOptions'

// Axios
import { yearReportGET } from '../../utils/cpmpanyAxios'

export default function ShareholderYear() {
    const stockCode = JSON.parse(sessionStorage.getItem("companyInfoData") || '{}')?.stockCode
    const [selectedYear, setSelectedYear] = useState(ADyears[ADyears.length - 1])


    const yearReport = async () => {
        try {
            await yearReportGET(stockCode, selectedYear)
        } catch (error) {
            console.error('API請求錯誤：', error)
        }
    }

    return (
        <Paper>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="justify" colSpan={2} sx={{ maxWidth: '230px', whiteSpace: 'nowrap' }}>
                                
                                <Select
                                    name="searchBar-id"
                                    value={selectedYear}
                                    onChange={(e) => setSelectedYear(e.target.value)}
                                    size="small"
                                    autoComplete="off"
                                    inputProps={inputPropsStyle}
                                    sx={statementInputSX}
                                    displayEmpty
                                >
                                    {ADyears.map((year) => (
                                        <MenuItem key={year} value={year}>
                                            {year}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ maxWidth: '230px', whiteSpace: 'nowrap', pl: '25px' }}>
                                股東會年報
                            </TableCell>
                            <TableCell align="justify">
                                <Button variant="contained" onClick={yearReport}>
                                    查看年報
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}
